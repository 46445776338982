import React, { useContext, useEffect } from 'react'
// import { makeStyles } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle'
import { AppContext } from '../../context/AppState'
import { useTranslation } from 'react-i18next'

import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'

import {
  Box,
  // Card,
  // CardContent,
  //Grid,
  IconButton,
  Typography,
  Tooltip
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

// const useStyles = makeStyles(presentationStyle)

const Disposal = () => {
  // const classes = useStyles()
  const isSmall = useMediaQuery('max-height: 600px')

  const { state } = useContext(AppContext)
  const { product, sectionActive } = state

  const { t, i18n } = useTranslation('wasteTable')

  useEffect(() => {
    // window.scrollTo(0, 0)
  }, [sectionActive])

  const waste = product?.waste
  const isWasteSecondaryComponentHide =
    product?.metadata?.isWasteSecondaryComponentHide

  function getMaterialName(name, isMultiComponent) {
    const result = isMultiComponent
      ? name
      : name?.slice(name?.indexOf('-') + 1)?.trim()

    return result
  }

  const getBorderColor = (wasteCollection) => {
    if (i18n.language === 'es-ES') {
      switch (wasteCollection) {
        case 'glass':
          return '#009900'
        case 'paper':
          return '#0055AA'
        case 'organic_waste':
          return '#8C5A37'
        case 'plastic':
          return '#FFE600'
        case 'dry_undifferentiated':
          return '#969696'
        case 'metals':
          return '#2894AE' //lattine con la plastica
        default:
          return '#EF7C3C'
      }
    } else {
      switch (wasteCollection) {
        case 'glass':
          return '#3D8443'
        case 'paper':
          return '#0D5EA6'
        case 'organic_waste':
          return '#674B42'
        case 'plastic':
          return '#FCD302'
        case 'dry_undifferentiated':
          return '#969696'
        case 'metals':
          return '#2894AE'
        default:
          return '#EF7C3C'
      }
    }
  }

  const getDisposalSpain = (fileUrl) => {
    return fileUrl ? (
      <img
        src={fileUrl}
        alt='icon'
        width='60'
        //style={{ marginRight: '15px' }}
      />
    ) : null
  }

  return (
    <div
      style={Object.assign(
        {},
        presentationStyle.disposalContainer,
        isSmall ? { marginTop: '16px' } : { background: 'white' }
      )}
    >
      {waste?.length > 0 && (
        <Typography
          variant='h5'
          sx={{ mb: 2, fontSize: '20px', fontWeight: 'bold' }}
        >{`${t('title')}`}</Typography>
      )}

      <div
        style={Object.assign(
          {},
          presentationStyle.disposalDivGridContainer,
          isSmall ? { marginTop: '16px' } : { background: 'white' }
        )}
      >
        <GridContainer
          style={{ width: '100%' }}
          direction='row'
          justify='center'
          alignItems='stretch'
          spacing={3}
        >
          {waste?.map((el, i) => (
            <React.Fragment key={'waste-' + i + '-'}>
              {/* <GridItem
                key={`waste-${i}`}
                xs={12}
                md={4}
                lg={4}
                style={isSmall && { marginBottom: 3 }}
              >
                <Card className='wasteCard'>
                  <Box
                    sx={{
                      padding: 2,
                      background:
                        el.wasteCollection === 'glass'
                          ? '#3D8443'
                          : el.wasteCollection === 'paper'
                          ? '#0D5EA6'
                          : el.wasteCollection === 'organic_waste'
                          ? '#674B42'
                          : el.wasteCollection === 'plastic'
                          ? '#FCD302'
                          : el.wasteCollection === 'dry_undifferentiated'
                          ? '#969696'
                          : el.wasteCollection === 'metals'
                          ? '#2894AE'
                          : '#EF7C3C'
                    }}
                  >
                    <Typography
                      variant='h5'
                      color='white'
                      sx={{ textTransform: 'uppercase', fontSize: '18px' }}
                    >
                      {`${t(`wasteCollectionType.${el.wasteCollection}`)}`}
                    </Typography>
                  </Box>
                  <CardContent>
                    <Typography variant='h5' sx={{ fontSize: '18px', mb: 1 }}>
                      {`${t(`componentType.${el.component}`)}`}
                    </Typography>

                    <Box sx={{ mb: 1 }}>
                      <Typography variant='body' sx={{ mb: 1 }}>
                        {el.material &&
                          getMaterialName(
                            `${t(`materials.${el.material.name}`)}`
                          )}

                        {''}

                        {el.material &&
                          Object.keys(el.material)?.length > 0 &&
                          el.material?.materials?.length > 0 && (
                            <>
                              {' ['}
                              {el.material.materials[0].code}
                            </>
                          )}

                        {el.material &&
                          Object.keys(el.material)?.length > 0 &&
                          el.material?.materials?.length > 0 &&
                          el.material?.materials[0]?.code.startsWith('GL') && (
                            <>
                              {' '}
                              {t(`materials.${el.material.materials[0].name}`)}
                            </>
                          )}
                        {']'}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        mb:
                          el.componentType === 'multicomponent' &&
                          !isWasteSecondaryComponentHide
                            ? 1
                            : 0
                      }}
                    >
                      <Typography variant='body' sx={{ mb: 1 }}>
                        {el.componentType === 'monocomponent'
                          ? t('materials.monoComponent')
                          : el.componentType === 'multicomponent'
                          ? t('materials.multiComponent')
                          : ''}
                        <br />
                      </Typography>
                    </Box>

                    {el.componentType === 'multicomponent' &&
                      !isWasteSecondaryComponentHide && (
                        <Typography variant='body'>
                          {el?.material &&
                            Object.keys(el?.material)?.length > 0 &&
                            el?.material?.materials?.length > 0 &&
                            t(`materials.${el.material.materials[0].name}`)}
                        </Typography>
                      )}
                  </CardContent>
                </Card>
              </GridItem> */}
              {/* NUOVA VISUALIZZAZIONE delle componenti di disposal */}
              <GridItem
                key={`waste-${i}`}
                xs={12}
                md={4}
                lg={4}
                style={isSmall && { marginBottom: 3 }}
              >
                {el.spanishCollectionImage?.isSelected &&
                i18n.language === 'es-ES' ? (
                  <Box
                    display='flex'
                    sx={{
                      //height: '100%',
                      alignContent: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Box
                      display='flex'
                      alignItems='flex-start'
                      sx={{
                        borderLeft: `7px solid #969696`, // Barra verde laterale
                        paddingLeft: 2 // Spazio a destra della barra
                      }}
                    >
                      <Box>
                        {/* <Typography variant='body1' color={`#000`}></Typography> */}
                        <p
                          style={{
                            fontWeight: 'bold'
                          }}
                        >
                          {`${t(`componentType.${el.component}`)}`}
                        </p>
                        {/* {el.spanishCollectionImage?.isSelected && ( */}
                        <Box
                          sx={{
                            alignContent: 'center'
                            // marginLeft: '10px',
                            // marginRight: '10px'
                          }}
                        >
                          {getDisposalSpain(el.spanishCollectionImage?.fileUrl)}
                        </Box>
                        {/* )} */}
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    display='flex'
                    sx={{
                      //height: '100%',
                      alignContent: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Box
                      display='flex'
                      alignItems='flex-start'
                      sx={{
                        borderLeft: `10px solid ${getBorderColor(
                          el.wasteCollection
                        )}`, // Barra verde laterale
                        paddingLeft: 2 // Spazio a destra della barra
                      }}
                    >
                      <Box>
                        <Typography
                          variant='h6'
                          component='div'
                          color={`${getBorderColor(el.wasteCollection)}`}
                          fontWeight='bold'
                          sx={{ textTransform: 'uppercase', fontSize: '18px' }}
                        >
                          {`${t(`wasteCollectionType.${el.wasteCollection}`)}`}
                        </Typography>

                        <Typography
                          variant='body1'
                          color={`${getBorderColor(el.wasteCollection)}`}
                        >
                          {`${t(`componentType.${el.component}`)}`}
                        </Typography>

                        <Typography variant='body1'>
                          {el.material &&
                            getMaterialName(
                              `${t(`materials.${el.material?.name}`)}`,
                              el.componentType === 'multicomponent'
                            )}
                          {''}
                          {el.material &&
                            Object.keys(el.material)?.length > 0 &&
                            el.material?.materials?.length > 0 && (
                              <>
                                {' ['}
                                {el.material.materials?.[0]?.code}
                              </>
                            )}

                          {el.material &&
                            Object.keys(el.material)?.length > 0 &&
                            el.material?.materials?.length > 0 &&
                            el.material?.materials?.[0]?.code?.startsWith(
                              'GL'
                            ) && (
                              <>
                                {' '}
                                {t(
                                  `materials.${el.material.materials?.[0]?.name}`
                                )}
                              </>
                            )}
                          {']'}
                        </Typography>

                        {/* <Typography variant='body2' color='text.primary'>
                      {el.componentType === 'monocomponent'
                        ? t('materials.monoComponent')
                        : el.componentType === 'multicomponent'
                        ? t('materials.multiComponent')
                        : ''}
                    </Typography> */}
                        {el.componentType === 'multicomponent' &&
                          !isWasteSecondaryComponentHide && (
                            <Typography variant='body2' color='text.primary'>
                              {/* {el?.material &&
                            Object.keys(el?.material)?.length > 0 &&
                            el?.material?.materials?.length > 0 &&
                            t(`materials.${el.material.materials[0].name}`)} */}
                              <div
                                style={{
                                  display: 'flex',
                                  gap: '15px'
                                }}
                              >
                                {el?.material &&
                                  Object.keys(el?.material)?.length > 0 &&
                                  el?.material?.materials?.length > 0 &&
                                  t(
                                    `materials.${el.material.materials[0].name}`
                                  )}

                                {el?.material?.materials?.[0]?.name ===
                                  'Tutti gli altri materiali in plastica' && (
                                  <Tooltip
                                    title={
                                      <div>
                                        <ul
                                          style={{
                                            margin: 0,
                                            paddingLeft: '20px',
                                            fontSize: '13px'
                                          }}
                                        >
                                          <li>
                                            {t('materials.Policarbonato')}
                                          </li>
                                          <li>{t('materials.Poliammide')}</li>
                                          <li>{t('materials.Copolimero')}</li>
                                          <li>
                                            {t('materials.Poliacrilonitrile')}
                                          </li>
                                          <li>
                                            {t('materials.Acrilonitrile')}
                                          </li>
                                          <li>{t('materials.Bioplastiche')}</li>
                                        </ul>
                                      </div>
                                    }
                                    arrow
                                    placement='top'
                                    enterTouchDelay={0}
                                  >
                                    <InfoIcon style={{ color: '#1976d2' }} />
                                  </Tooltip>
                                )}
                              </div>
                            </Typography>
                          )}
                      </Box>
                    </Box>
                  </Box>
                )}
              </GridItem>
            </React.Fragment>
          ))}
        </GridContainer>
        {waste?.length > 0 && (
          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <Typography
              variant='body'
              color={'#969696'}
              sx={{ fontSize: '14px' }}
            >
              <IconButton size='small' style={{ color: '#969696' }}>
                <InfoIcon />
              </IconButton>
              {t('checkMunicipalProvisions')}
            </Typography>
          </Box>
        )}
      </div>
    </div>
  )
}

export default Disposal
