import React, { useContext, useEffect } from 'react'
import { Link, Typography } from '@mui/material'
// import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'

import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import Map from '../Map/Map'
import TextWithUrl from '../TextWithUrl/TextWithUrl'
import { AppContext } from '../../context/AppState'

import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle'
import './style.css'
import map from '../../assets/img/contacts/map.svg'
// import cart from '../../assets/img/contacts/cart.svg'
// import site from '../../assets/img/contacts/site.svg'
import phone from '../../assets/img/contacts/phone.svg'
import email from '../../assets/img/contacts/email.svg'

import { isValidNumberLat, isValidNumberLng } from '../../utils/utils'

// const useStyles = makeStyles(presentationStyle)

const CompanyInfo = () => {
  // const classes = useStyles()
  const { t } = useTranslation('companyInfo')
  const { state } = useContext(AppContext)
  const { company, sectionActive } = state

  useEffect(() => {
    // window.scrollTo(0, 0)
  }, [sectionActive])

  const extractProductionKey = (attributeKey) => {
    let translatedString = ''
    if (attributeKey?.includes('productions')) {
      const productionsArray = attributeKey?.split(' ')
      const [prodString, ...rest] = productionsArray
      translatedString = [t(prodString), ...rest]?.join(' ')
    } else {
      translatedString = t(attributeKey)
    }

    return translatedString
  }

  return (
    <div style={presentationStyle.companyContainer}>
      {company && company.name && (
        <Typography
          variant='h5'
          sx={{ mb: 2, fontSize: '20px', fontWeight: 'bold' }}
        >
          {t('titleInfo')}
        </Typography>
      )}
      <GridContainer
        direction='row'
        justify='center'
        alignItems='flex-start'
        spacing={2}
      >
        <GridItem xs={12} md={7}>
          {company && company.name && (
            <p
              style={{
                fontWeight: 'bold'
              }}
            >
              {company.name}
            </p>
          )}
          {company && company.description ? (
            <p
              style={{
                //display: 'flex',
                alignItems: 'center',
                textAlign: 'justify'
              }}
            >
              {company && company.name && company.description && (
                <TextWithUrl
                  className='companyDescriptionContainer'
                  companyName={company.name}
                  text={company.description}
                ></TextWithUrl>
              )}
            </p>
          ) : (
            ''
          )}
          <p style={{ display: 'flex', alignItems: 'center' }}>
            {company && company.geolocation?.address && (
              <>
                <img
                  src={map}
                  alt='icon'
                  width='25'
                  style={{ marginRight: '15px' }}
                />
                {`${company?.geolocation?.address?.street || ''}${
                  company?.geolocation?.address?.city
                    ? ', ' + company.geolocation.address.city
                    : ''
                }`}
              </>
            )}
            <br></br>
          </p>
          {company && company.email ? (
            <p style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={email}
                alt='icon'
                width='25'
                style={{ marginRight: '15px' }}
              />
              <Link color='inherit' href={`mailto:${company.email}`}>
                {company.email}
              </Link>
            </p>
          ) : (
            ''
          )}
          {/* {company && company.website ? (
            <p style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={site}
                alt='icon'
                width='25'
                style={{ marginRight: '15px' }}
              />
              <Link color='inherit' href={`${company.website}`}>
                {company.website}
              </Link>
            </p>
          ) : (
            ''
          )} */}
          {/* {company && company.ecommerce ? (
                  <p style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={cart}
                      alt='cart'
                      width='25'
                      style={{ marginRight: '15px' }}
                    />
                    <Link color='inherit' href={`${company.ecommerce}`}>
                      {company.ecommerce}
                    </Link>
                  </p>
                ) : (
                  ''
                )} */}
          {company && company.phoneNumber ? (
            <p style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={phone}
                alt='icon'
                width='25'
                style={{ marginRight: '15px' }}
              />
              <Link color='inherit' href={`tel:${company.phoneNumber}`}>
                {company.phoneNumber}
              </Link>
            </p>
          ) : (
            ''
          )}
          {company && company.mobileNumber ? (
            <p style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={phone}
                alt='icon'
                width='25'
                style={{ marginRight: '15px' }}
              />
              <Link color='inherit' href={`tel:${company.mobileNumber}`}>
                {company.mobileNumber}
              </Link>
            </p>
          ) : (
            ''
          )}
          {company && company.attributes && company.attributes.length > 0
            ? company.attributes.map((attribute, index) => (
                <p key={`attribute-${index}`}>
                  <span style={presentationStyle.boldText}>
                    {`${extractProductionKey(attribute.name)}: `}
                  </span>
                  {attribute.value}{' '}
                  {attribute.name === 'surface' ? t('surfaceUnit') : ''}
                </p>
              ))
            : ''}
        </GridItem>

        {company &&
        company.geolocation &&
        company.geolocation.lat &&
        company.geolocation.lng &&
        isValidNumberLat(String(company.geolocation.lat)) &&
        isValidNumberLng(String(company.geolocation.lng)) ? (
          <GridItem xs={12} md={5}>
            <div style={presentationStyle.companyImgContainer}>
              <Map
                zoom={14}
                isHeader={true}
                lat={String(company.geolocation.lat)}
                lng={String(company.geolocation.lng)}
              />
            </div>
          </GridItem>
        ) : (
          ''
        )}
      </GridContainer>
    </div>
  )
}

// Assegna il displayName per semplificare il debugging
CompanyInfo.displayName = 'CompanyInfo'
export default CompanyInfo
